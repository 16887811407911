import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import Header from 'components/Header'
import PromoCarousel from 'page/home/PromoCarousel'
import Footer from 'components/Footer'
import ServiceSection from 'page/home/ServiceSection'
import DigipayAppSection from 'page/home/DigipayAppSection'
import AboutSection from 'page/home/AboutSection'
import GetDigipaySection from 'page/home/GetDigipaySection'
import BlogSection from 'page/home/BlogSection'
import SignUpSection from 'page/home/SignUpSection'
import AboutDigipaySection from 'page/home/AboutDigipaySection'
import MobileCollage from 'page/home/MobileCollage'
import MissionVision from 'page/home/MissionVision'
import TestimonialSection from 'page/home/TestimonialSection'
import NewTestimonialSection from '../page/home/NewTestimonialSection'
import PromotionalVideo from 'page/home/PromotionalVideo'
import MobileTesti from 'page/home/MobileTesti'
import MobileTestimonial from '../page/home/MobileTestimonial'
import '../components/css/layout.css'
import Seo from '../components/Seo'
import TagManager from 'react-gtm-module'
class IndexPage extends React.Component {
  tagManagerArgs = {
    gtmId: process.env.GATSBY_REACT_APP_GTM_CONTAINER_ID,
  }

  state = { isMobile: false }

  componentDidMount() {
    TagManager.initialize(this.tagManagerArgs)
    this.setState({ isMobile: window.innerWidth < 800 })
  }

  render() {
    return (
      <div>
        <Seo
          title="Digipay PH"
          description="Digipay is a digital payments and financial services platform with
a mobile wallet, an industry leading biller ecosystem,
and an interoperable backend."
          keywords="digipay.ph/,Digital, Payments, Digital Payments, Financial Services Platform, Mobile Wallet, Leading Biller Ecosystem, Interoperable Backend"
        />
        <Header />
        {this.state.isMobile ? <MobileCollage /> : <PromoCarousel />}
        <AboutDigipaySection />
        <ServiceSection />
        <DigipayAppSection />
        <GetDigipaySection />
        {this.state.isMobile ? <MobileTestimonial /> : <NewTestimonialSection />}
        <BlogSection />
        <SignUpSection />
        {this.state.isMobile ? null : <PromotionalVideo />}
        {this.state.isMobile ? <MissionVision /> : <AboutSection />}
        <Footer />
      </div>
    )
  }
}
export default IndexPage
