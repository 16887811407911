import React from 'react'
import { Carousel, Image } from 'react-bootstrap'

import DigipaySssPagibig from 'images/digipay-sss-pagibig-banner.jpg'
import DigipadalaLibre from 'images/digipadala-libre.jpg'
import PayYourBills from 'images/digipay-pay-your-bills.png'
import DigipayCaltex from 'images/digipay-caltex-banner.jpg'
import AgentBankingNewBanner from 'images/agent-banking-new-banner.png'
import QRPHBanner from '../../images/qrph-banner.jpg'

class PromoCarousel extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.handleSelect = this.handleSelect.bind(this)

    this.state = {
      index: 0,
      direction: null,
    }
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
    })
  }

  render() {
    const { index } = this.state
    return (
      <Carousel activeIndex={index} onSelect={this.handleSelect}>
        <Carousel.Item>
          <Image
            src={QRPHBanner}
            alt="qrph-banner"
            className="w-100 rounded"
          />
        </Carousel.Item>

        <Carousel.Item>
          <Image
            src={AgentBankingNewBanner}
            alt="agent-banking-new-banner"
            className="w-100 rounded"
          />
        </Carousel.Item>

        <Carousel.Item>
          <Image
            src={DigipaySssPagibig}
            alt="digipadala-libre-image"
            className="w-100 rounded"
          />
        </Carousel.Item>

        <Carousel.Item>
          <Image
            src={DigipadalaLibre}
            alt="digipadala-libre-image"
            className="w-100 rounded"
          />
        </Carousel.Item>

        <Carousel.Item>
          <Image
            src={PayYourBills}
            alt="services-image"
            className="w-100 rounded"
          />
        </Carousel.Item>

        <Carousel.Item>
          <Image
            src={DigipayCaltex}
            alt="digipay-caltex-image"
            className="w-100 rounded"
          />
        </Carousel.Item>
      </Carousel>
    )
  }
}
export default PromoCarousel
