import React from 'react'
import { Image, Col } from 'react-bootstrap'
import DigipadalaLibre from 'images/digipadala-libre.jpg'
import 'components/css/mobile-collage.css'
import PayYourBills from 'images/digipay-pay-your-bills.png'
import DigipayCaltex from 'images/digipay-caltex-banner.jpg'
import DigipaySssPagibig from 'images/digipay-sss-pagibig-banner.jpg'
import AgentBankingNewBanner from 'images/agent-banking-new-banner.png'
import QRPHBanner from '../../images/qrph-banner.jpg'

const MobileCollage = () => (
  <div id="mobile-images">
    <Image src={QRPHBanner} alt="hero-image" width="100%" />
    <Image src={AgentBankingNewBanner} alt="hero-image" width="100%" />
    <Col id="col-image-2">
      <Image src={DigipaySssPagibig} alt="hero-image" width="100%" />
    </Col>
    <Image src={DigipadalaLibre} alt="hero-image" width="100%" />
    <Image src={PayYourBills} alt="hero-image" width="100%" />
    <Image src={DigipayCaltex} alt="hero-image" width="100%" />
  </div>
)
export default MobileCollage
