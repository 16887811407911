import React from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import classnames from 'classnames'
import 'components/css/new-testi-mobile.css'
import Agent1 from 'images/testi/Anna Marie.jpeg'
import Agent2 from 'images/testi/Arnold.jpeg'
import Agent3 from 'images/testi/Liandro.jpg'
import Agent4 from 'images/testi/Rebecca.jpeg'

var agents = [
  {
    name: 'Anna Marie',
    text:
      'Simula ng ipakilala sa akin ng aking kuya ang Digipay nagkaroon ako ng kita sa bawat transaksyon na nakatutulong sa akin bilang isang mag-aaral.',
    image: Agent1,
    details: {
      occupation: 'Owner of Arnold Dry Goods Store',
      location: 'Quezon Province'
    }
  },

  {
    name: 'Arnold Vender',
    text:
      'Bilang isang authorized Digipay Agent/Distributor, ako po ay lubos nagpapasalamat dahil ang Digipay ay isa sa aking katuwang at kasama sa pagpapalago at pagbibigay ng dekalidad at mapagkakatiwalaang serbisyong pinansyal.',
    image: Agent2,
    details : {
      occupation: 'Owner of Arnold’s Bills Payment, Reloading & Remittance Services',
      location: 'Quezon Province'
    }
  },

  {
    name: 'Liandro',
    text:
      'Since nagsimula kami ng maliit na pagkakakitaan, ang DIGIPAY ang una namin naging partner.',
    image: Agent3,
    details : {
      occupation: 'Owner of KKNJZY e-Sari and Payments Online Shop',
      location: 'Romblon'
    }
  },

  {
    name: 'Rebecca',
    text:
      'Digipay is the reason I acquired more friends, and it has made my life better.',
    image: Agent4,
    details : {
      occupation: 'Owner of Travelmate Bills Payment',
      location: 'Taguig'
    }
  },

]

class MobileTestimonial extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentIndex: 0 }
  }

  componentDidMount() {
    var self = this
    this.intervalID = setInterval(function() {
      self.setState(state => {
        return { currentIndex: (state.currentIndex + 1) % agents.length }
      })
    }, 15000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalID)
  }

  render() {
    const data = agents.map((agent, index) => (
      <div
        key={index}
        className={classnames(
          'new-testimonial-mobile__elements',
          index === this.state.currentIndex && 'show'
        )}
      >
        <Row className="new-testimonial-mobile__content">
          <p align="center">{agent.text}</p>
        </Row>
        <Row className="new-testimonial-mobile__agent" align="right">
          <Col xs={12} className='' align="center">
            <Image
              className="new-testimonial-mobile__agent-image"
              src={agent.image}
              alt="agent-1"
            />
          </Col>
          <Col xs={12} className="new-testimonial-mobile__agent-fullname" align="right">
            <h3 align="center">{agent.name}</h3>
            <p align="center">{agent.details.occupation}</p>
            <p align="center">{agent.details.location}</p>
          </Col>
        </Row>
      </div>
    ))

    return <div className="new-testimonial-mobile">{data}</div>
  }
}
export default MobileTestimonial
