import React from 'react'
import { Image, Row, Col, Button } from 'react-bootstrap'
import Phone from 'images/phone.png'
import Playstore from 'images/icons/playstore.png'
import 'components/css/digipay-app.css'

const DigipayAppSection = () => (
  <div className="digipay-app">
    <Row>
      <Col xs={12} md={6}>
        <Image className="digipay-app__phone" src={Phone} alt="phone-asset" />
      </Col>

      <Col xs={12} md={6}>
        <Row className="justify-content-center">
          <a href="https://play.google.com/store/apps/details?id=com.fsgcapital.digipay&hl=en">
            <Image id="playstore" src={Playstore} alt="playstore-asset" width="100%"/>
          </a>
        </Row>
        <Row className="justify-content-center">
          <Button
            href="https://play.google.com/store/apps/details?id=com.fsgcapital.digipay&hl=en"
            variant="light"
            className="digipay-app__btn-download"
          >
            Available on Desktop and Android Devices
          </Button>
        </Row>
      </Col>
    </Row>
  </div>
)
export default DigipayAppSection
